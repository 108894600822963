import { faArrowsRotate, faCompress, faExpand, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogPanel } from '@headlessui/react'
import { UIMatch, useMatches, useRevalidator } from '@remix-run/react'
import { UseMutateFunction, useMutation } from '@tanstack/react-query'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { Fragment, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { z } from 'zod'

import { userApiRoutes } from '@rooquest/common'

import { GuideConfig } from '~/features/guides/registry'
import useCurrentUser from '~/hooks/useCurrentUser'
import { api } from '~/utils/api-client-singleton.client'

export default function Guide(props: {
  pov: 'sender' | 'recipient'
  guide: Map<string, GuideConfig>
}) {
  const constraintsRef = useRef(null)
  const actionButtonRef = useRef(null!)
  const currentUser = useCurrentUser()
  const [expanded, setExpanded] = useState(false)

  if (props.pov === 'sender' && !currentUser) {
    throw new Error('No user found')
  }

  const { state, stepsToShow, mutate } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    props.pov === 'sender' ? useCurrentUserGuides(props.guide) : useRecipientGuides(props.guide)

  if (!state.activeGuide || !state.step) return null

  return createPortal(
    <Fragment>
      {/*-------- Drag constraint container --------*/}
      <motion.div
        ref={constraintsRef}
        className="absolute inset-[16px] z-1000 pointer-events-none"
      />

      {!expanded ?
        <motion.div
          layout={false}
          drag="x"
          dragConstraints={constraintsRef}
          dragTransition={{ bounceDamping: 100, power: 0.05, timeConstant: 100 }}
          dragElastic={0.1}
          initial={{ opacity: 0, bottom: -100, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1, bottom: 16 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ type: 'spring', bounce: 0.25, duration: 0.4 }}
          className={cn(
            'fixed bottom-[16px] left-[16px] rounded-[10px] shadow-lg sm:w-[328px] min-[1921px]:w-[500px] max-w-full z-1002 overflow-hidden bg-[#505050]/85 backdrop-blur-sm group',
            'cursor-ew-resize'
          )}
          onAnimationComplete={() => (actionButtonRef.current as HTMLButtonElement).focus()}
        >
          <Content
            state={state}
            step={state.step}
            stepsToShow={stepsToShow}
            expanded={expanded}
            handleExpand={setExpanded}
            mutate={mutate}
            buttonRef={actionButtonRef}
          />
        </motion.div>
      : <Dialog open onClose={() => setExpanded(false)} className="fixed inset-0 z-1002">
          <div className="grid h-screen place-items-center">
            {/*-------- Expanded background overlay --------*/}
            <motion.div
              className="absolute inset-0 z-1001 bg-[#505050]/85 backdrop-blur-[2px]"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />

            <DialogPanel
              as={motion.div}
              initial={{ opacity: 0, y: 100, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition
              onAnimationComplete={() => (actionButtonRef.current as HTMLButtonElement).focus()}
              className="rounded-[20px] shadow-lg w-[500px] min-[1921px]:w-[700px] max-w-full z-1002 overflow-hidden bg-[#505050]/85 backdrop-blur-sm group"
            >
              <Content
                state={state}
                step={state.step}
                stepsToShow={stepsToShow}
                expanded={expanded}
                handleExpand={setExpanded}
                mutate={mutate}
              />
            </DialogPanel>
          </div>
        </Dialog>
      }
    </Fragment>,
    document.body
  )
}

function Content(props: {
  state: GuideState
  step: GuideConfig['steps'][number]
  stepsToShow: React.MutableRefObject<GuideConfig['steps']>
  expanded: boolean
  handleExpand: (expanded: boolean) => void
  mutate: UseMutateFunction<
    void,
    Error,
    { intent: 'next' | 'back' | 'close' | 'reset'; key: string }
  >
  buttonRef?: React.MutableRefObject<HTMLButtonElement>
}) {
  // checks to see if state.step.mediaSrc is an image or a video
  const isMediaSrcImage = props.step.mediaSrc.match(/\.(jpeg|jpg|gif|png|webp)$/)
  const lastStep = props.stepsToShow.current.at(-1)
  const firstStep = props.stepsToShow.current.at(0)

  return (
    <>
      {/*-------- Expand Button --------*/}
      <button
        tabIndex={-1}
        onClick={() => props.handleExpand(!props.expanded)}
        className={cn(
          // hidden on mobile
          'hidden md:block',
          'absolute top-3 right-3 z-10 transition-all',
          'flex justify-center items-center h-8 w-8',
          'opacity-0 rounded-full shadow-sm text-[20px] text-gray-600/85',
          'backdrop-blur-[2px] hover:backdrop-blur-[4px] hover:text-gray-600 group-hover:opacity-100 group-hover:bg-white/60'
        )}
      >
        <FontAwesomeIcon icon={props.expanded ? faCompress : faExpand} />
      </button>

      {/*-------- Media Section --------*/}
      <AnimatePresence mode="wait">
        <motion.div
          key={props.step.key + 'step-media'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
          className="overflow-hidden flex items-center justify-center"
        >
          {isMediaSrcImage ?
            <img src={props.step.mediaSrc} alt="Guide Example" className="pointer-events-none" />
          : <video
              src={props.step.mediaSrc}
              autoPlay
              loop
              playsInline
              controls={props.step.mediaInteractive}
              muted
            >
              {/*TODO: Add tracks for accessibility on videos*/}
              <track src="something for accessibility" />
            </video>
          }
        </motion.div>
      </AnimatePresence>
      <div className="relative flex flex-col h-full p-6">
        {/*-------- Close Button --------*/}
        <div className="absolute top-4 right-12 z-10">
          <button
            aria-label="Close guide"
            onClick={() => props.mutate({ key: props.step.key, intent: 'reset' })}
            className="h-6 w-6 text-gray-300 hover:bg-[#828282] hover:text-white transition-colors rounded-lg flex items-center justify-center"
          >
            <FontAwesomeIcon icon={faArrowsRotate} />
          </button>
        </div>
        <div className="absolute top-4 right-6 z-10">
          <button
            aria-label="Close guide"
            onClick={() => props.mutate({ key: props.step.key, intent: 'close' })}
            className="h-6 w-6 text-gray-300 hover:bg-[#828282] hover:text-white transition-colors rounded-lg flex items-center justify-center"
          >
            <FontAwesomeIcon icon={faTimes} className="text-lg" />
          </button>
        </div>

        {/*-------- Guide Content --------*/}
        <AnimatePresence mode="wait" initial={false}>
          <motion.div
            initial={{ x: '100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '-100%', opacity: 0 }}
            transition={{ type: 'spring', bounce: 0.25, duration: 0.4 }}
            className="flex-1 mb-4 text-white"
            key={props.step.key + 'step-content'}
          >
            <h2 className="text-xl font-extrabold leading-[27px] mb-2">{props.step.heading}</h2>
            <p className="text-sm leading-[20px] tracking-[.17px]">{props.step.content}</p>
          </motion.div>
        </AnimatePresence>

        {/*-------- Guide Actions --------*/}
        <div className="flex items-center justify-between gap-4">
          <div className="text-sm leading-[20px] tracking-[.17px] text-white">
            <AnimatePresence mode="popLayout" initial={false}>
              <motion.div
                key={props.step.key + 'step-number'}
                initial={{ y: -16, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 16, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="inline-block"
              >
                {props.stepsToShow.current.map((s) => s.key).indexOf(props.step.key) + 1}
              </motion.div>
            </AnimatePresence>
            {' of '} {props.stepsToShow.current.length}
          </div>

          <div>
            {props.stepsToShow.current.length > 1 && props.step.key !== firstStep?.key && (
              <button
                ref={props.buttonRef}
                tabIndex={0}
                className="px-[14px] py-[6px] bg-[#828282] rounded-[24px] text-white text-[14px] transition-colors hover:bg-[#828282]/70 focus:outline-hidden focus:ring-2 focus:ring-white mr-4"
                onClick={() => props.mutate({ key: props.step.key, intent: 'back' })}
              >
                Back
              </button>
            )}
            <button
              ref={props.buttonRef}
              className="px-[14px] py-[6px] bg-[#828282] rounded-[24px] text-white text-[14px] transition-colors hover:bg-[#828282]/70 focus:outline-hidden focus:ring-2 focus:ring-white"
              onClick={() => props.mutate({ key: props.step.key, intent: 'next' })}
            >
              {props.step.key === lastStep?.key ? 'Finish' : 'Next'}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

type GuideState = {
  activeGuide: { key: string; config: GuideConfig } | null
  step: GuideConfig['steps'][number] | null
}

type GuideHook = (guide: Map<string, GuideConfig>) => {
  state: GuideState
  stepsToShow: React.MutableRefObject<GuideConfig['steps']>
  mutate: UseMutateFunction<
    void,
    Error,
    { intent: 'next' | 'close' | 'back' | 'reset'; key: string }
  >
}

const useCurrentUserGuides: GuideHook = (guide) => {
  const currentUser = useCurrentUser()
  const revalidator = useRevalidator()
  const matches = useMatches()

  if (!currentUser) throw new Error('No user found')

  const userGuideState = useRef(currentUser.guides)
  const stepsToShow = useRef<GuideConfig['steps']>([])

  const [state, setState] = useState<GuideState>({ activeGuide: null, step: null })

  useEffect(() => {
    for (const [key, config] of guide) {
      if (config.enabled({ matchedRoutes: matches.map((m: UIMatch) => m.id) })) {
        setState(() => {
          stepsToShow.current = config.steps.filter((step) => {
            return !userGuideState.current[key]?.includes(step.key)
          })
          return { activeGuide: { key, config }, step: stepsToShow.current[0] }
        })

        break
      } else {
        setState({ activeGuide: null, step: null })
      }
    }
  }, [matches, guide])

  const { mutate } = useMutation({
    mutationFn: async (input: { intent: 'next' | 'back' | 'close' | 'reset'; key: string }) => {
      if (!state.activeGuide) return

      if (input.intent === 'close') {
        // append all steps to the guides object
        userGuideState.current = {
          ...userGuideState.current,
          [state.activeGuide.key]: state.activeGuide.config.steps.map((step) => step.key),
        }
      } else if (input.intent === 'next') {
        // append the current step to the userGuideState.current object
        userGuideState.current = {
          ...userGuideState.current,
          [state.activeGuide.key]: [
            ...(userGuideState.current[state.activeGuide.key] || []),
            input.key,
          ],
        }

        setState((current) => {
          const currentIndex = stepsToShow.current.findIndex((step) => step.key === input.key)
          if (currentIndex === stepsToShow.current.length - 1) {
            return { ...current, step: null }
          }
          return { ...current, step: stepsToShow.current[currentIndex + 1] }
        })
      } else if (input.intent === 'back') {
        // remove the last step from the users guide
        userGuideState.current = {
          ...userGuideState.current,
          [state.activeGuide.key]: userGuideState.current[state.activeGuide.key].slice(0, -1),
        }

        setState((current) => {
          const currentIndex = stepsToShow.current.findIndex((step) => step.key === input.key)
          return { ...current, step: stepsToShow.current[currentIndex - 1] }
        })
      } else if (input.intent === 'reset') {
        userGuideState.current = {
          ...userGuideState.current,
          [state.activeGuide.key]: [],
        }
        setState((current) => {
          return { ...current, step: stepsToShow.current[0] }
        })
      }

      await api(userApiRoutes, 'update', { data: { guides: userGuideState.current } })
      if (input.intent === 'close') revalidator.revalidate()
    },
  })

  return { state, stepsToShow, mutate }
}

const savedGuideSchema = z.record(z.array(z.string()))

const useRecipientGuides: GuideHook = (guide) => {
  const matches = useMatches()
  const userGuideState = useRef<Record<string, string[]>>({})
  const stepsToShow = useRef<GuideConfig['steps']>([])

  const [state, setState] = useState<GuideState>({ activeGuide: null, step: null })

  useEffect(() => {
    const userStorage = localStorage.getItem('guides')

    if (userStorage && savedGuideSchema.safeParse(JSON.parse(userStorage))) {
      userGuideState.current = JSON.parse(userStorage)
    }

    for (const [key, config] of guide) {
      if (config.enabled({ matchedRoutes: matches.map((m: UIMatch) => m.id) })) {
        setState(() => {
          stepsToShow.current = config.steps.filter((step) => {
            return !userGuideState.current[key]?.includes(step.key)
          })
          return { activeGuide: { key, config }, step: stepsToShow.current[0] }
        })

        break
      } else {
        setState({ activeGuide: null, step: null })
      }
    }
  }, [matches, guide])

  const { mutate } = useMutation({
    mutationFn: async (input: { intent: 'next' | 'back' | 'close' | 'reset'; key: string }) => {
      if (!state.activeGuide) return

      if (input.intent === 'close') {
        // append all steps to the guides object
        userGuideState.current = {
          ...userGuideState.current,
          [state.activeGuide.key]: state.activeGuide.config.steps.map((step) => step.key),
        }
      } else if (input.intent === 'next') {
        // append the current step to the userGuideState.current object
        userGuideState.current = {
          ...userGuideState.current,
          [state.activeGuide.key]: [
            ...(userGuideState.current[state.activeGuide.key] || []),
            input.key,
          ],
        }

        setState((current) => {
          const currentIndex = stepsToShow.current.findIndex((step) => step.key === input.key)
          if (currentIndex === stepsToShow.current.length - 1) {
            return { ...current, step: null }
          }
          return { ...current, step: stepsToShow.current[currentIndex + 1] }
        })
      } else if (input.intent === 'back') {
        // remove the last step from the users guide
        userGuideState.current = {
          ...userGuideState.current,
          [state.activeGuide.key]: userGuideState.current[state.activeGuide.key].slice(0, -1),
        }

        setState((current) => {
          const currentIndex = stepsToShow.current.findIndex((step) => step.key === input.key)
          return { ...current, step: stepsToShow.current[currentIndex - 1] }
        })
      } else if (input.intent === 'reset') {
        userGuideState.current = {
          ...userGuideState.current,
          [state.activeGuide.key]: [],
        }
        setState((current) => {
          return { ...current, step: stepsToShow.current[0] }
        })
      }

      // store on local storage
      localStorage.setItem('guides', JSON.stringify(userGuideState.current))
    },
  })

  return { state, stepsToShow, mutate }
}
