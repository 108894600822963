import { faCheckCircle, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoaderFunctionArgs, data, redirect } from '@remix-run/node'
import { Link, Outlet, useLoaderData, useSearchParams } from '@remix-run/react'
import { AnimatePresence } from 'framer-motion'
import isEmpty from 'lodash-es/isEmpty'

import { pluralize } from '@rooquest/common'

import AppSidebar from '~/components/AppSidebar'
import { ClientOnly, useClientOnly } from '~/components/ClientOnly'
import { routes } from '~/config/app-routes'
import Guide from '~/features/guides/components/Guide'
import { guideRegistry } from '~/features/guides/registry'
import useCurrentUser from '~/hooks/useCurrentUser'
import { protectLoader } from '~/server/session.server'
import { DEFAULT_USER_PREFERENCES, userPreferencesCookie } from '~/server/user-preferences.server'

export { ErrorBoundary } from '~/components/RouteBoundaries'

export let loader = async ({ request }: LoaderFunctionArgs) => {
  const { currentUser, memberships } = await protectLoader(request)

  if (!memberships.length) {
    throw redirect(routes.onboarding)
  }

  const organizations = memberships.map((membership) => membership.organization)

  // // set the current organization to the cookie
  // const currentOrganization =
  //   organizations.find((org) => org.slug === params.orgSlug) || organizations[0]

  const $userPreferences = await userPreferencesCookie.parse(request.headers.get('Cookie'))

  return data(
    {
      $currentUser: currentUser,
      $memberships: memberships,
      $userPreferences,
      organizations,
    },
    {
      headers: {
        'Set-Cookie': await userPreferencesCookie.serialize(
          isEmpty($userPreferences) ? DEFAULT_USER_PREFERENCES : $userPreferences
        ),
      },
    }
  )
}

export default function Component() {
  const { organizations } = useLoaderData<typeof loader>()
  const isClient = useClientOnly()
  if (!isClient) return null

  return (
    <div className="flex">
      <ClientOnly>
        <AnimatePresence>
          <Guide guide={guideRegistry} pov="sender" />
        </AnimatePresence>
      </ClientOnly>

      {/* sidebar */}
      <AppSidebar organizations={organizations} />

      {/* main content */}
      <main className="flex-1">
        <BannerNewOrganization />
        <BannerInviteAccepted />
        <BannerOpenInvites />

        <Outlet />
      </main>
    </div>
  )
}

function BannerNewOrganization() {
  const [searchParams, setSearchParams] = useSearchParams()

  if (searchParams.get('action') !== 'new-organization') {
    return null
  }

  return (
    <div role="alert" className="py-3 px-6 bg-[#EDF7ED] flex items-start justify-between gap-4">
      <div className="flex items-start gap-3">
        <FontAwesomeIcon icon={faCheckCircle} className="text-[#61A413] relative top-1 text-lg" />
        <p className="font-medium text-[#1E4620]">
          You've created a new organization. To manage your organizations, go to{' '}
          <Link to={routes.settingsProfile} className="underline whitespace-nowrap">
            Settings / Profile.
          </Link>
        </p>
      </div>

      <button
        onClick={() => setSearchParams({}, { replace: true })}
        className="text-[#1E4620] flex items-center justify-center h-6 w-6 hover:bg-[#1E4620]/20 rounded-sm"
      >
        <FontAwesomeIcon icon={faTimes} className="text-lg" />
      </button>
    </div>
  )
}

function BannerInviteAccepted() {
  const [searchParams, setSearchParams] = useSearchParams()

  if (searchParams.get('action') !== 'invite-accepted') {
    return null
  }

  return (
    <div role="alert" className="py-3 px-6 bg-[#EDF7ED] flex items-start justify-between gap-4">
      <div className="flex items-start gap-3">
        <FontAwesomeIcon icon={faCheckCircle} className="text-[#61A413] relative top-1 text-lg" />
        <p className="font-medium text-[#1E4620]">
          You've accepted an invite to this organization. To manage your organizations, go to{' '}
          <Link to={routes.settingsProfile} className="underline whitespace-nowrap">
            Settings / Profile.
          </Link>
        </p>
      </div>

      <button
        onClick={() => setSearchParams({}, { replace: true })}
        className="text-[#1E4620] flex items-center justify-center h-6 w-6 hover:bg-[#1E4620]/20 rounded-sm"
      >
        <FontAwesomeIcon icon={faTimes} className="text-lg" />
      </button>
    </div>
  )
}

export function BannerOpenInvites() {
  const currentUser = useCurrentUser()
  if (!currentUser) throw new Error('No user found')

  if (!currentUser.invites.length) {
    return null
  }

  return (
    <div role="alert" className="py-3 px-6 bg-[#FFF4E5] flex items-center justify-between gap-4">
      <div className="flex items-start gap-3">
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          className="text-[#EF8923] relative top-1 text-lg"
        />
        <p className="font-medium text-[#663C00]">
          You have{' '}
          {pluralize`${currentUser.invites.length} ${['pending organization invite', 'pending organization invitations']}`}
          . Manage them from the{' '}
          <Link to={routes.settingsProfile} className="underline whitespace-nowrap">
            User Settings
          </Link>{' '}
          page.
        </p>
      </div>

      <Link
        to={routes.settingsProfile}
        className="text-[#663C00] px-2 py-1.5 hover:bg-[#663C00]/20 rounded-full leading-none"
      >
        Respond
      </Link>
    </div>
  )
}
